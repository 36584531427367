.homepage {
  height: 100vh;
  position: relative;
  @media only screen and (max-width: 700px) {
    margin: 50px 0;
  }
  .share {
    left: 214px;
    bottom: -39px;
    top: -17px;
    @media only screen and (max-width: 700px) {
      left: 120%;
    }
  }
  .battle-page__share-image {
    height: 30px;
    position: absolute;
    right: 39px;
    @media only screen and (max-width: 832px) {
      right: 29px;
    }
  }
  .main-share {
    right: -42px;
    top: 5px;
    bottom: 0;
  }
  &__no-results {
    font-weight: bold;
    text-align: center;
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    top: 40%;
    text-transform: uppercase;
    font-size: $font-lg;
    color: $blackthought;
  }
  .battles {
    width: 723px;
    margin: 40px auto;
    margin-bottom: 80px;
    padding-bottom: 100px;
    @media only screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: inherit;
      margin-bottom: 280px;
      padding-bottom: 50px;
    }
    .wrapper {
      position: relative;
      width: 166px;
      height: 166px;
      display: inline-block;
      left: 44px;
      margin-right: 72px;
      margin-bottom: 180px;
      @media only screen and (max-width: 700px) {
        margin: 0 auto;
        margin-bottom: 40%;
        width: 38%;
        height: 190px;
        margin-right: 60px;
      }
      @media only screen and (max-width: 414px) {
        margin: 0 auto;
        margin-bottom: 180px;
        width: 50%;
        left: 0;
        display: block;
      }
    }
  }
  .image {
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .contents {
    position: relative;
    bottom: 36px;
  }
  .date {
    color: #fff;
    font-size: 54px;
    font-weight: bold;
    margin: 0;
  }
  .title-wrapper {
    position: relative;
    font-size: $font-lg;
    font-weight: bold;
    color: $blackthought;
    text-transform: uppercase;
  }
  .title {
    display: table-row;
    line-height: 20px;
  }
  .link {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: none;
    background: $blackthought;
    color: #fff;
    font-size: $font-md;
    outline: none;
    font-weight: bold;
    padding: 10px 40px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    top: 20px;
    position: relative;
    transition-duration: .2s;
    &:hover {
      transition-duration: .2s;
      background-color: lighten($blackthought, 4%);
    }
  }
  .main-battle {
    width: 740px;
    margin: 0 auto;
    background: $carti;
    margin-top: 90px;
    position: relative;
    z-index: 1;
    height: 340px;
    box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
    @media only screen and (max-width: 700px) {
      display: none;
    }
    .content-wrapper {
      width: 640px;
      margin: 0 auto;
      position: relative;
    }
    .battle-header {
      font-size: 64px;
      font-weight: bold;
      text-transform: uppercase;
      right: 0;
      color: $blackthought;
      position: absolute;
      bottom: 194px;
    }
    .image {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      position: relative;
      left: 0px;
      width: 296px;
      bottom: 43px;
    }
    .contents {
      position: absolute;
      right: 0px;
      top: 30px;
      text-align: right;
      @media only screen and (max-width: 700px) {
        top: 25px;
      }
    }
    .title-wrapper {
      margin-top: 12px;
    }
    .date, .title {
      // font-size: 37px;
      font-size: 33px;
      line-height: 40px;
      letter-spacing: 1px;
    }
    .link {
      position: relative;
      top: 62px;
      font-size: 20px;
    }
    .battle-footer {
      color: $beachhouse;
      font-size: 52px;
      text-transform: uppercase;
      font-weight: bold;
      position: absolute;
      top: 214px;
    }
  }
  .main-battle-wrapper {
    position: relative;
    width: 166px;
    height: 166px;
    display: inline-block;
    left: 44px;
    margin-right: 72px;
    margin-bottom: 180px;
    @media only screen and (max-width: 700px) {
      margin: 0 auto;
      margin-bottom: 40%;
      width: 38%;
      height: 190px;
      margin-right: 60px;
    }
    @media only screen and (max-width: 414px) {
      margin: 0 auto;
      margin-bottom: 180px;
      width: 50%;
      left: 0;
      display: block;
    }
  }
  .main-battle:before {
    content: "";
    background: url('../images/black-grain.png') center center;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .15;
    background-size: contain;
  }
}

.footer {
  background: url('../images/brown-grain.png');
  height: 64px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $blackthought;
  border-top: 2px solid $blackthought;
  z-index: 3;
  &__link {
    text-decoration: none;
  }
  &__text {
    @include header-text;
    top: 12px;
    text-transform: uppercase;
    @media only screen and (max-width: 375px) {
      font-size: $font-xsm;
    }
  }
  &__contact {
    @include header-text
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    left: 250px;
    top: 24px;
    @media only screen and (max-width: 700px) {
      position: static;
      // margin-top: 23px;
    }
    @media only screen and (max-width: 375px) {
      font-size: $font-xsm;
    }
  }
  .left {
    left: 50px;
    position: relative;
    @media only screen and (max-width: 414px) {
      left: 20px;
    }
  }
  .right {
    right: 50px;
    position: relative;
    @media only screen and (max-width: 414px) {
      right: 20px;
    }
  }
}

.onboarding {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 80px;
  height: 80vh;
  @media only screen and (max-width: 414px) {
    margin-top: 100px;
  }
  @media only screen and (max-width: 325px) {
    margin-top: 60px;
  }
  &__logo {
    width: 90px;
    margin-bottom: 15px;
  }

  &__title {
    @include header-text;
    color: $blackthought;
    font-size: $font-xxl;
    text-decoration: none;
  }
  &__input-wrapper {
    position: relative;
    width: 240px;
    margin: 0 auto;
  }
  &__label {
    position: absolute;
    left: 2px;
    bottom: 54px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-sm;
    // color: #2c201e90;
    color: transparent;
    transition: .1s ease-in;
  }
  &__input {
    background: transparent;
    border: none;
    border: 2px solid #2c201e55;
    margin: 12px 0;
    width: 220px;
    font-size: $font-default;
    color: $blackthought;
    outline: none;
    background: $beachhouse;
    padding: 10px 7px 7px 10px;
    border-radius: 4px;
  }
  &__input-wrapper ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    background: transparent;
    font-size: $font-default;
    color: #2c201e90;
    opacity: .5;
  }

  &__input-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
    background: transparent;
    font-size: $font-default;
    color: #2c201e90;
    opacity: .5;
  }

  &__input-wrapper ms-input-placeholder { /* Microsoft Edge */
    background: transparent;
    font-size: $font-default;
    color: #2c201e90;
    opacity: .5;
  }
  &__input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &__input:focus + label {
    color: #2c201e;
  }
  &__input:valid {
  }
  &__cta-wrapper {
    width: 240px;
    margin: 0 auto;
    position: relative;
  }
  &__cta, &__spotify-cta,  &__twitter-cta {
    background-color: rgb(63, 52, 47);
    border-radius: 4px;
    width: 100%;
    color:#fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: $font-md;
    cursor: pointer;
    outline: none;
    padding: 10px 0;
    margin-top: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border: none;
    transition-duration: .2s;
    &:hover {
      background-color: lighten(rgb(63, 52, 47), 4%) !important;
    }
  }
  &__spotify-cta {
    background: #1db954;
    margin-top: 10px;
    &:hover {
      background-color: lighten(#1db954, 4%)!important;
    }
  }
  &__twitter-cta {
    background: #49a1f2;
    margin-top: 10px;
    height: 16px;
    &:hover {
      background-color: lighten(#49a1f2, 4%)!important;
    }
  }
  // &__twitter-cta path {
  //   display: none;
  // }
  // &__twitter-cta rect {
  //   display: none;
  // }
  &__text {
    color: $blackthought;
    top: 15px;
    cursor: pointer;
    font-size: $font-md;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
  }
  .error-msg {
    color: lighten($wineHouse, 20%);
    margin: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
    font-size: $font-default;
    color: $blackthought;
  }
  input :-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
    font-size: $font-default;
    color: $blackthought;
  }
  input :-internal-autofill-previewed {
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
    font-size: $font-default;
    color: $blackthought;
  }
  input :-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
    font-size: $font-default;
    color: $blackthought;
  }
  // &[type="email"], &[type="email"]:-internal-autofill-selected {
  //       background-image: url("/ressources/img/email.svg") !important;
  //   }
}

.chat-list {
  position: relative;
  height: 90vh;
  background-size: cover;
  @media only screen and (max-width: 414px) {
    display: none;
  }
  &__insta-cta {
    width: 136px;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 2px $blackthought;
    background-color: $beachhouse;
    margin: 0 auto;
    text-align: center;
    color: $blackthought;
    z-index: 3;
    position: relative;
    &__link {
      text-decoration: none;
    }
    &__text {
      margin: 0 auto;
      display: inline;
      font-size: $font-md;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      top: 4px;
    }
    &__logo {
      height: 17px;
      width: 17px;
      position: relative;
      top: 8px;
      left: 2px;
    }
  }
  &__wrapper {
    display: table;
    margin: 0 auto;
    z-index: 1;
    position: relative;
  }
  &__gradient {
    position: absolute;
    height: 40px;
    width: 100%;
    z-index: 1;
    top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-image: linear-gradient($blackthought, #2c201e92);
    display: none;
    // top: 32px;
    // bottom: 0;
    // border-none;
  }
  &__grain {
    position: absolute;
    width: 100%;
    opacity: .40;
    height: 100%;
    top: 0;
  }
  // &__blur {
  //   position: absolute;
  //   width: 100%;
  //   filter: blur(10px);
  //   -webkit-filter: blur(10px);
  //   height: 90vh;
  //   top: 0;
  // }
  &__scroll {
    opacity: 1;
    // background-color: #ffffff;
    left: 25%;
    height: 200px;
    width: 320px;
    overflow: scroll;
    // opacity: .92;
    border-radius: 4px;
    // background: $blackthought;
    padding-bottom: 10px;
    &__comment-wrapper {
      display: flex;
      color: $beachhouse;
      width: 90%;
      margin: 0 auto;
      font-size: $font-md;
      position: relative;
      opacity: .92;
      border-radius: 4px;
      background: #2c201e;
      margin-top: 12px;
      padding: 0px 20px 15px;
    }
    &__comment-user {
      font-weight: bold;
      margin-left: 5px;
    }
    &__comment-check {
      position: relative;
      right: 6px;
      width: 12px;
      top: 1px;
    }
    &__comment-text {
      margin-left: 12px;
      position: relative;
      word-break: break-word;
      line-height: 18px;
      &__hide {
        color: $carti;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__date {
      position: absolute;
      font-size: 10px;
      left: 25px;
      bottom: 0px;
    }
    &__input-wrapper {
      background: $blackthought;
      padding: 10px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-top: 20px;
    }
    &__input {
      background: none;
      border: none;
      border-bottom: 2px solid rgba(242, 228, 207, 0.70);
      width: 70%;
      outline: none;
      color: $beachhouse;
      margin-left: 10px;
      font-size: $font-md;
    }
    &__input-wrapper ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $beachhouse;
      opacity: .5;
      text-transform: uppercase;
      font-size: $font-sm;
    }

    &__input-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $beachhouse;
      opacity: .5;
      text-transform: uppercase;
      font-size: $font-sm;
    }

    &__input-wrapper ms-input-placeholder { /* Microsoft Edge */
      color: $beachhouse;
      opacity: .5;
      text-transform: uppercase;
      font-size: $font-sm;
    }
    &__button {
      margin-left: 10px;
      width: 60px;
      position: relative;
      top: 1px;
      border-radius: 2px;
      text-transform: uppercase;
      color: $blackthought;
      background: $beachhouse;
      border: none;
      padding: 5px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      transition-duration: .5;
      &:hover {
        background-color: lighten($beachhouse, 4%);
      }
    }
  }
}

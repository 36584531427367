.user-list {
  background: $blackthought;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 3;
  opacity: 0.95;
  &__wrapper {
    margin-left: 25px;
    overflow: scroll;
    height: 520px;
    width: 90%;
    position: relative;
    top: 20px;
    @media only screen and (max-width: 414px) {
      margin: 0 auto;
    }
  }
  &__search {
    background: none;
    border: none;
    border-bottom: 2px solid rgba(242, 228, 207, 0.70);
    outline: none;
    color: $beachhouse;
    font-size: $font-md;
    position: absolute;
    right: 0;
    top: 40px;
    text-align: right;
    width: 180px;
    border-radius: 0;
    @media only screen and (max-width: 414px) {
      width: 50%;
    }
  }
  &__close {
    position: absolute;
    height: 18px;
    right: 2%;
    top: 7px;
    cursor: pointer;
    z-index: 2;
  }
  &__clear {
    position: relative;
    right: 170px;
    top: 10px;
    width: 10px;
    cursor: pointer;
    @media only screen and (max-width: 414px) {
      right: 160px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    position: relative;
  }
  &__header ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $beachhouse;
    opacity: .5; /* Firefox */
    text-transform: uppercase;
    font-size: $font-sm;
    text-align: right;
  }

  &__header :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $beachhouse;
    opacity: .5;
    text-transform: uppercase;
    font-size: $font-sm;
    text-align: right;
  }

  &__header ms-input-placeholder { /* Microsoft Edge */
    color: $beachhouse;
    opacity: .5;
    text-transform: uppercase;
    font-size: $font-sm;
    text-align: right;
  }
  &__title {
    font-size: $font-xlg;
    text-transform: uppercase;
    color: $beachhouse;
  }
  &__user-wrapper {
    position: relative;
  }
  &__username {
    font-size: $font-sm;
    color: $beachhouse;
    font-weight: bold;
    border-bottom: 1px solid rgba(242, 228, 207, 0.25);
    padding-bottom: 14px;
    text-transform: uppercase;
  }
  &__check {
    position: absolute;
    top: 0px;
    left: 53px;
    width: 12px;
  }
  &__date {
    position: absolute;
    right: 0;
    color: $beachhouse;
    font-style: italic;
    font-size: $font-sm;
    bottom: 1px;
  }
}

body {
  margin: 0;
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: url('http://api.thumbr.it/whitenoise-361x370.png?background=f1e3ceff&noise=626262&density=88&opacity=15');
  background-color: #f1e3ce;
  z-index: 1;
  min-height: 100%;
  // margin-top: 30px !important;
}

body:after {
  content: "";
  background: url('../images/black-grain.png') center center;
  opacity: 0.15;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}

html,body {
   margin:0;
   padding:0;
   width: 100vw;
   height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.background {
  // background-color: #f1e3ce;
  height: 100%;
}

// .background:before, {
//   content: "";
//   background: url('../images/black-grain.png') center center;
//   opacity: 0.15;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   position: absolute;
//   z-index: -1;
// }

.app-content-wrapper {
  height: 100%;
}

.root {
  height: 100%;
}

.list-error-msg {
  position: relative;
  top: 20%;
  margin: 0 auto;
  text-align: center;
  background: lighten(#722a1f, 10%);
  color:#f1e3ce;
  width: 50%;
  padding: 10px 0;
  border-radius: 4px;
  z-index: 2;
}

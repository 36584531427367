.splash-page {
  margin: auto;
  background-image: linear-gradient(to bottom,rgba(44,32,30,0.85),$blackthought);
  height: 100vh;
  overflow: scroll;
  &__back {
    position: absolute;
    text-decoration: none;
    cursor: pointer;
    color: $beachhouse;
    left: 20px;
    font-size: $font-md;
    opacity: .4;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
    @media only screen and (max-width: 414px) {
      opacity: 1;
    }
  }
  &__logo {
    width: 100px;
  }
  &__contents {
    margin: 0 auto;
    position: relative;
    top: 20%;
    text-align: center;
    width: 330px;
    @media only screen and (max-width: 500px) {
      width: 260px;
    }
  }
  &__contents-bottom {
    top: 10%;
    color: $beachhouse;
  }
  &__about-btn {
    color: $beachhouse;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    span {
      font-size: 10px;
      position: relative;
      bottom: 1px;
      left: 4px;
    }
  }
  &__msg {
    color: $beachhouse;
    margin-bottom: 20px;
    line-height: 20px;
    &__link {
      color: $beachhouse;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  input {
    border: none;
    background: transparent;
    color: #f1e3ce;
    width: 297px;
    display: flex;
    height: 34px;
    font-size: 14px;
    position: relative;
    padding: 4px 15px 4px 15px;
    background-color: #f1e3ce1a;
    outline: none;
    border-radius: 30px;
    border: 1px solid transparent;
    &:focus {
      border: 1px solid $beachhouse;
    }
    @media only screen and (max-width: 500px) {
      width: 225px;
    }
  }
  button {
    background-color: $carti;
    border: none;
    height: 42px;
    border: none;
    border-radius: 30px;
    color: $beachhouse;
    text-transform: uppercase;
    margin-top: 20px;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition-duration: .5s;
    &:hover {
      background: lighten($carti, 5%);
    }
  }
  .message {
    margin-bottom: 20px;
  }
  .error {
    color: lighten($wineHouse, 10%);
  }
  div {
    color: $carti !important;
    margin-bottom: 20px;
  }
}

.battle-page {
  height: 90vh;
  position: relative;
  &__share-wrapper {
    position: absolute;
    top: 5px;
    left: 50%;
    cursor: pointer;
    z-index: 100;
    text-align: left;
    z-index: 1;
    margin-left: 7.5%;
    @media only screen and (max-width: 832px) {
      left: 15vw;
      top: 85px;
      margin-left: 0;
    }
    // @media only screen and (max-width: 375px) {
    //   top: 105px;
    // }
    // @media only screen and (max-width: 375px) {
    //   top: 100px;
    // }
    // @media only screen and (max-width: 320px) {
    //   right: 10px;
    // }
  }
  &__share {
    text-transform: uppercase;
    font-size: $font-xsm;
    font-weight: bold;
    position: absolute;
    right: 2px;
    text-align: left;
    top: 11px;
    &:hover {
      text-decoration: underline;
    }
    @media only screen and (max-width: 320px) {
      display: none;
    }
  }
  &__share-image {
    height: 20px;
    position: absolute;
    right: 29px;
    @media only screen and (max-width: 320px) {
      top: 15px;
    }
  }
  &__no-matchups {
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
    background: $beachhouse;
    width: 80%;
    border: 2px solid $blackthought;
    border-radius: 4px;
    z-index: 1;
    position: relative;
    text-transform: uppercase;
  }
  &__playlist {
    top: 370px;
    position: absolute;
    width: 320px;
    margin: 0 auto;
    height: 220px;
    right: 0;
    left: 0;
    border-radius: 4px;
    overflow: hidden;
  }
  &__response-message {
    justify-content: space-between;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    text-align: center;
    z-index: 20;
    top: 10px;
    border-radius: 4px;
    padding: 0;
    height: 40px;
    transition: visibility 0s, opacity 0.3s ease-in;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    @media only screen and (max-width: 832px) {
      width: 85%;
    }
    &__text {
      padding: 0;
      margin: 0;
      position: relative;
      top: 12px;
      color: $beachhouse;
      left: 15px;
    }
    &__exit {
      position: absolute;
      right: 11px;
      height: 12px;
      cursor: pointer;
      bottom: 13px;
      color: $beachhouse;
    }
  }
  .success {
    background: $carti;
    width: 30%;
    @media only screen and (max-width: 832px) {
      width: 85%;
    }
  }
  .failure {
    background: lighten($wineHouse, 10%);
    width: 340px;
    @media only screen and (max-width: 320px) {
      width: 300px;
      font-size: $font-sm;
    }
  }
  &__wrapper {
    position: relative;
    &__two:before {
      @include divider;
      width: 2px;
      height: 90vh;
      @media only screen and (max-width: 832px) {
        display: none;
      }
    }
    &__one {
      width: 50%;
      display: inline-block;
      height: 100%;
      position: relative;
      @media only screen and (max-width: 832px) {
        display: none;
      }
    }
    &__two {
      width: 50%;
      display: inline-block;
      float: right;
      height: 100%;
      position: relative;
      @media only screen and (max-width: 832px) {
        width: 100%;
      }
    }
  }
  &__artist {
    &__header {
      display: flex;
      position: relative;
      margin-bottom: 120px;
      @media only screen and (max-width: 832px) {
        margin-top: 10px;
      }
      @media only screen and (max-width: 414px) {
        margin-bottom: 130px;
      }
      @media only screen and (max-width: 360px) {
        margin-bottom: 120px;
      }
      &__wrapper {
        &__1, &__2 {
          display: flex;
          justify-content: center;
          position: absolute;
          @media only screen and (max-width: 832px) {
            margin-top: 20px;
          }
        }
        &__1.graphic, &__2.graphic {
          @media only screen and (max-width: 832px) {
            margin-top: 0px;
            width: 140px;
          }
          @media only screen and (max-width: 667px) {
            margin-top: 0px;
            width: 17.6vw;
          }
        }
        &__1 {
          right: 50%;
          margin-right: 25px;
          @media only screen and (max-width: 832px) {
            margin-right: 35px;
          }
        }
        &__1.graphic {
          @media only screen and (max-width: 832px) {
            margin-right: 3.4vw;
          }
        }
        &__2 {
          margin-left: 25px;
          @media only screen and (max-width: 832px) {
            margin-left: 35px;
          }
        }
        &__2.graphic {
          @media only screen and (max-width: 832px) {
            margin-left: 3.4vw;
          }
        }
      }
      &__1, &__2 {
        width: 50%;
        display: inline;
        // justify-content: center;
      }
      &__1:after {
        @include divider;
        right: 50%;
        z-index: 1;
        top: 0;
        height: 540px;
        @media only screen and (max-width: 832px) {
          height: 550px;
          top: -10px;
        }
        @media (max-height: 667px) and (min-width: 375px) {
            height: 490px;
        }
        @media (max-height: 640px) and (min-width: 360px) {
            height: 450px;
        }
        @media (max-height: 568px) and (min-width: 320px) {
            height: 385px;
        }
      }
      &__1.graphic:after {
        height: 640px;
        width: 1px;
        @media only screen and (max-width: 832px) {
          top: 0;
        }
        @media only screen and (max-width: 667px) {
          height: 104vw;
          top: 0vw;
        }
      }
      &__image {
        &__1, &__2 {
          height: 75px;
          width: 75px;
          background-size: cover;
          opacity: 0.9;
          border-radius: 4px;
          box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.5);
          border: solid 2px $blackthought;
          margin-top: 30px;
          @media only screen and (max-width: 832px) {
            margin-top: 25px;
          }
        }
        &__1.graphic, &__2.graphic {
          height: 65px;
          width: 65px;
          border: none;
          border: 1px solid $blackthought;
          @media only screen and (max-width: 832px) {
            height: 65px;
            width: 65px;
            margin-top: 4vw;
          }
          @media only screen and (max-width: 667px) {
            margin-top: 4vw;
            height: 9vw;
            width: 9vw;
          }
        }
        &__2 {
          left: 25px;
        }
        &__1 {
          right: 25px;
        }
      }
      &__name {
        &__1, &__2 {
          color: $blackthought;
          font-size: 21px;
          line-height: 24px;
          top: 18px;
          position: relative;
          max-width: 140px;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: 832px) {
            position: absolute;
            text-align: center;
            top: -23px;
            white-space: nowrap;
            max-width: 90px;
          }
        }
        &__1.graphic, &__2.graphic {
          @media only screen and (max-width: 832px) {
            max-width: 70px;
            position: relative;
            text-align: initial;
            top: 18px;
            white-space: normal;
            width: 100%;
            font-size: 21px;
            line-height: 20px;
          }
          @media only screen and (max-width: 667px) {
            max-width: 8vw;
            position: relative;
            text-align: initial;
            top: 2.2vw;
            white-space: normal;
            width: 100%;
            font-size: 2.6vw;
            line-height: 2.6vw;
          }
        }
        &__1 {
          right: 20px;
          direction: rtl;
          // @media only screen and (max-width: 832px) {
          //   right: 0;
          // }
        }
        &__1.graphic {
          right: 20px;
          @media only screen and (max-width: 832px) {
            right: 2vw;
          }
        }
        &__2 {
          left: 20px;
          // @media only screen and (max-width: 832px) {
          //   left: 0;
          // }
        }
        &__2.graphic {
          left: 20px;
          @media only screen and (max-width: 832px) {
            left: 2vw;
          }
        }
      }
    }
    &__header.graphic {
      @media only screen and (max-width: 832px) {
        margin-top: 0;
        // margin-bottom: 15vw;
        margin-bottom: 0;
      }
    }
    &__user-count {
      &__1, &__2 {
        position: absolute;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-xsm;
        top: 110px;
        @media only screen and (max-width: 832px) {
          top: 107px;
        }
        @media only screen and (max-width: 667px) {
          top: 15.1vw;
          font-size: 1.3vw;
        }
      }
      &__1 {
        left: 48px;
        @media only screen and (max-width: 667px) {
          left: 7vw;
        }
      }
      &__2 {
        right: 48px;
        @media only screen and (max-width: 667px) {
          right: 7vw;
        }
      }
    }
  }
  &__graphic {
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #2c201ed9;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    #divToPrint {
      background: url('../images/house-grain.png');
      position: absolute;
      width: 640px;
      height: 640px;
      // left: 50%;
      // top: 50%;
      z-index: 40;
      @media only screen and (max-width: 667px) {
        width: 90vw;
        height: 104vw;
      }
    }
    &__msg-wrapper {
      position: absolute;
      top: -48px;
      color: $beachhouse;
      font-size: $font-sm;
      line-height: 6px;
      display: none;
      @media only screen and (max-width: 667px) {
        display: block;
      }
    }
    &__wrapper {
      position: absolute;
      width: 640px;
      height: 640px;
      left: 50%;
      top: 50%;
      // z-index: 40;
      // background: url('../images/house-grain.png');
      margin-top: -320px;
      margin-left: -320px;
      @media only screen and (max-width: 667px) {
        width: 90vw;
        height: 104vw;
        margin-top: -45vw;
        margin-left: -45vw;
      }
    }
    &__wrapper:after {
      content: "";
      background: url("/static/media/black-grain.ad488d0d.png") center center;
      opacity: 0.15;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      position: fixed;
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
    }
    &__button-wrapper {
      text-align: center;
      @media only screen and (max-width: 667px) {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
    &__button {
      bottom: 20px;
      position: absolute;
      left: 50%;
      text-align: center;
      background-color: $carti;
      color: #fff;
      height: 30px;
      font-weight: bold;
      font-size: $font-md;
      width: 640px;
      height: 44px;
      border-radius: 4px;
      border: none;
      text-transform: uppercase;
      z-index: 20;
      margin-top: -310px;
      margin-left: -320px;
      cursor: pointer;
      box-shadow: 2px 4px 6px 2px rgba(0,0,0,.5);
      z-index: 40;
      @media only screen and (max-width: 667px) {
        margin: 0 auto;
        width: 90vw;
        left: 0;
        position: relative;
      }
    }
    &__username {
      right: 46px;
      z-index: 30;
      top: 0px;
      color: $blackthought;
      font-weight: bold;
      font-size: $font-sm;
      position: absolute;
      text-transform: uppercase;
      @media only screen and (max-width: 832px) {
        font-size: 1.4vw;
        right: 7.6%;
      }
    }
    &__site-name {
      left: 39px;
      z-index: 30;
      margin-left: 10px;
      bottom: 0px;
      color: $blackthought;
      font-weight: bold;
      font-size: $font-sm;
      position: absolute;
      text-transform: uppercase;
      @media only screen and (max-width: 832px) {
        font-size: 1.4vw;
        left: 5.2vw;
      }
    }
    &__bonus {
      z-index: 30;
      top: 0;
      color: $blackthought;
      font-weight: bold;
      font-size: $font-sm;
      position: absolute;
      text-transform: uppercase;
      float: right;
      left: 43px;
      @media only screen and (max-width: 832px) {
        font-size: 1.3vw;
        left: 7.6%;
      }
    }
    &__exit {
      height: 24px;
      position: absolute;
      right: 0;
      float: right;
      top: -36px;
      cursor: pointer;
    }
  }
  &__results {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 48px;
    color: #fff;
    font-weight: bold;
    font-size: 34px;
    // @media only screen and (max-width: 832px) {
    //   font-size: 4.3vw;
    //   margin-top: 6vw;
    // }
    @media only screen and (max-width: 667px) {
      font-size: 4.3vw;
      margin-top: 6.5vw;
    }
    &__1, &__2 {
      position: relative;
      text-shadow: 2px 2px #2c201e96;
    }
    &__1 {
      left: 36px;
      @media only screen and (max-width: 832px) {
        left: 4vw;
      }
    }
    &__2 {
      right: 36px;
      @media only screen and (max-width: 832px) {
        right: 4vw;
      }
    }
  }
  &__matchups {
    margin-top: 30px;
    overflow: scroll;
    height: 380px;
    position: relative;
    @media (max-height: 667px) and (min-width: 375px) {
        height: 310px;
    }
    @media (max-height: 640px) and (min-width: 360px) {
        height: 300px;
    }
    @media (max-height: 568px) and (min-width: 320px) {
        height: 225px;
    }
    &__matchup {
      display: flex;
      justify-content: center;
      border-radius: 5px;
      border: solid 2px $blackthought;
      background-color: $beachhouse;
      width: 85%;
      margin: 25px auto;
      height: 45px;
      position: relative;
    }
    &__matchup.graphic {
      height: 15px;
      margin: 6.7px auto;
      border: 1px solid $blackthought;
      @media only screen and (max-width: 667px) {
        width: 76.5vw;
        margin: 1.2vw auto;
        height: 2.3vw;
      }
    }
    &__song {
      display: contents;
      &__link {
        p {
          &:hover {
            cursor: pointer;
          }
        }
      }
      &__song-title {
        &__1, &__2 {
          color: $blackthought;
          font-size: $font-sm;
          font-weight: bold;
          text-transform: uppercase;
          position: absolute;
          top: 5px;
          max-width: 30%;
          white-space: nowrap;
          overflow: scroll;
          @media only screen and (max-width: 915px) {
            max-width: 25%;
          }
          @media only screen and (max-width: 414px) {
            max-width: 27%;
          }
          @media only screen and (max-width: 375px) {
            font-size: $font-xsm;
            top: 8px;
          }
        }
        &__1.graphic, &__2.graphic {
          font-size: 11px;
          top: -9px;
          @media only screen and (max-width: 667px) {
            font-size: 1.8vw;
            top: -1.5vw;
          }
          @media only screen and (max-width: 510px) {
            top: -1.7vw;
          }
        }
        &__1 {
          right: 50%;
          margin-right: 30px;
          @media only screen and (max-width: 414px) {
            margin-right: 20px;
          }
          @media only screen and (max-width: 375px) {
            margin-right: 10px;
          }
        }
        &__2 {
          left: 50%;
          margin-left: 30px;
          @media only screen and (max-width: 414px) {
            margin-left: 20px;
          }
          @media only screen and (max-width: 375px) {
            margin-left: 10px;
          }
        }
      }
      &__vote-count {
        &__1, &__2 {
          color: $blackthought;
          font-size: $font-md;
          font-weight: bold;
          position: absolute;
          text-decoration: none;
          border-bottom: 2px solid $blackthought;
          cursor: pointer;
          line-height: 10px;
          top: 4px;
        }
        &__1 {
          left: 0;
          margin-left: 20px;
        }
        &__2 {
          right: 0;
          margin-right: 20px;
        }
      }
      /* Customize the label (the container) */
      .container {
        display: block;
        position: absolute;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .song1 {
        left: 20px;
        top: 13px;
      }
      .song2 {
        right: 3px;
        top: 13px;
      }
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #eee;
        border: 2px solid $blackthought;
        border-radius: 4px;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #c77d4a;
      }

      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: #eee;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 5px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid $blackthought;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &__checkbox {
        &__1, &__2 {
          height: 25px;
          width: 25px;
          background: #fff;
        }
        input[type=checkbox] {
          border: solid 2px $blackthought;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  &__matchups.graphic {
    margin-top: 0;
    height: 80%;
    top: 10px;
    _::-webkit-full-page-media, _:future, :root .safari_only {
      top: 130px;
    }
    @media only screen and (max-width: 832px) {
      margin-top: 115px;
    }
    @media only screen and (max-width: 667px) {
      margin-top: 15.2vw;
      height: 82vw;
    }
  }
  &__vote {
    &__button-wrapper {
      width: 100%;
      text-align: center;
      top: 40px;
      position: relative;
      z-index: 2;
      @media only screen and (max-width: 414px) {
          top: 30px;
      }
      @media (max-height: 640px) and (min-width: 360px) {
          top: 20px;
      }
      @media (max-height: 568px) and (min-width: 320px) {
          top: 30px;
      }
    }
    &__button {
      background-color: $blackthought;
      border-radius: 4px;
      width: 85%;
      color: $beachhouse;
      font-weight: bold;
      height: 45px;
      text-transform: uppercase;
      font-size: $font-md;
      z-index: 1;
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border: none;
      transition-duration: .2s;
      cursor: pointer;
      &:hover {
        background-color: lighten($blackthought, 4%);
      }
      &__link {
        text-decoration: none;
      }
    }
  }
}

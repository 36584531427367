.header {
  background-color: $blackthought;
  height: 58px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $blackthought;
  background: url('../images/brown-grain.png');
  // position: fixed;
  // top: 0;
  // width: 100%;
  // z-index: 4;
  &__logos {
    display: flex;
    margin-left: 50px;
    @media only screen and (max-width: 414px) {
      margin-left: 20px;
    }
    .company-logo {
      margin-left: 20px;
    }
    .verzuz-logo {
      margin-right: 10px;
    }
    &__logo {
      height: 34px;
      position: relative;
      top: 12px;
    }
    &__text {
      @include header-text;
      top: 22px;
      text-decoration: none;
      @media only screen and (max-width: 414px) {
        font-size: $font-xsm;
        top: 24px;
      }
    }

    &__text.about {
      margin-left: 20px;
    }
  }
  &__cta {
    display: flex;
    margin-right: 50px;
    @media only screen and (max-width: 414px) {
      margin-right: 20px;
    }
    &__search {
      height: 34px;
      position: relative;
      top: 6px;
      margin-right: 10px;
      cursor: pointer;
    }
    &__text {
      @include header-text;
      top: 22px;
      cursor: pointer;
      text-decoration: none;
      @media only screen and (max-width: 414px) {
        font-size: 10px;
        top: 24px;
      }
    }
  }
}

.search-modal {
  width: 100%;
  height: 100%;
  z-index: 4;
  opacity: 0.85;
  background-color: #2c201e;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  &__contents {
    width: 683px;
    text-align: center;
    top: 20%;
    position: absolute;
    z-index: 10;
    color: $beachhouse;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
  &__header {
    font-size: $font-xxl;
    font-weight: bold;
    letter-spacing: 2.6px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &__input {
    background: none;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 2px solid $beachhouse;
    margin-top: 20px;
    height: 50px;
    text-align: center;
    color: $beachhouse;
    font-weight: bold;
    font-size: 24px;
    border-radius: 0;
    @media only screen and (max-width: 720px) {
      width: 80%;
    }
  }
  &__contents ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $beachhouse;
    opacity: .45;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    text-align: center;
  }

  &__contents :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $beachhouse;
    opacity: .45;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    text-align: center;
  }

  &__contents ms-input-placeholder { /* Microsoft Edge */
    color: $beachhouse;
    opacity: .45;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    text-align: center;
  }
  &__msg {
    font-size: $font-sm;
    color: $beachhouse;
    font-weight: bold;
    position: relative;
    top: 5px;
  }
  &__clear {
    position: relative;
    cursor: pointer;
    float: right;
    bottom: 30px;
    @media only screen and (max-width: 414px) {
      display: none !important;
    }
  }
  &__close {
    height: 25px;
    position: absolute;
    left: 0;
    bottom: 130px;
    cursor: pointer;
    @media only screen and (max-width: 720px) {
      left: 10%;
    }
  }
}

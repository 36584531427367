@mixin header-text {
  font-size: $font-sm;
  font-weight: bold;
  color: $beachhouse;
  letter-spacing: .5px;
  position: relative;
}

@mixin divider {
  content: "";
  background-color: #000;
  position: absolute;
  width: 2px;
  display: block;
}

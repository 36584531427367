.error-page {
  text-align: center;
  margin-top: 200px;
  height: 100vh;
  &__error-wrapper {
    color: $blackthought
  }
  &__error-title {
    font-size: $font-xxl;
    text-transform: uppercase;
  }
  &__error-msg {
    position: relative;
    bottom: 20px;
  }
}

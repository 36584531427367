.about-page {
  margin: auto;
  background: $beachhouse;
  height: 100vh;
  overflow: scroll;
  scroll-padding-bottom: 20px;

  &__text-wrapper {
    width: 80%;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;

    @media only screen and (max-width: 500px) {
      width: 90%;
    }
  }

  &__text {
    width: 60%;
    float: right;

    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }

  &__title {
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    font-weight: 600;
  }

  &__section {
    width: 80%;
    margin: 0 auto;
    display: flex;
    margin-top: 40px;

    @media only screen and (max-width: 500px) {
      width: 90%;
      display: block;
    }

    &__image {
      // width: 25%;
      min-width: 220px;
      min-height: 220px;
      background-size: cover;
      background-position: center;
      margin-right: 20px;

      @media only screen and (max-width: 768px) {
        width: 40%;
      }

      @media only screen and (max-width: 500px) {
        width: 100%;
        min-height: 300px;
        display: block;
      }
    }

    &__image.right {
      margin-left: 20px;
    }

    &__bio-wrapper  {

      position: relative;
      top: 50px;
      // top: 20px;
      // width: 75%;
      a {
        color: $blackthought;
      }

      @media only screen and (max-width: 768px) {
        top: 0px;
        width: 60%;
        line-height: 19px;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__bio-title {
      font-size: $font-lg;
      font-weight: 700;
      margin-bottom: 0px;
      padding-bottom: 0;
    }

    &__bio-subtitle {
      margin-top: 0;
      font-size: $font-md;
    }
  }

  &__section.right {
    text-align: right;
  }
}

.bump {
  margin-bottom: 140px;
}
